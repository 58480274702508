#stand-alone-viewer {
	margin: 0;
	padding: 0;
	width: 100vw;
	height: 100vh;
	overflow: hidden;
}
#stand-alone-viewer #viewer {
	position: absolute;
	width: 80%;
	height: 100%;
}
#stand-alone-viewer #sidebar {
	position: absolute;
	right: 0;
	width: 20%;
	height: 100%;
	padding: 10px;
	box-sizing: border-box;
	overflow: auto;
}
#stand-alone-viewer #sidebar #log {
	width: 100%;
	word-break: break-word;
}
#stand-alone-viewer #sidebar #log > * {
	display: block;
	padding: 6px 20px;
	border-bottom: dashed lightgray 1px;
}
